import React, { useEffect, useState } from 'react'
import Sidebar from 'react-sidebar';
import axios from 'axios';
import { Box, Typography, Button } from '@mui/material'
import { Link, useNavigate } from "react-router-dom";

// supabase
import { createClient } from "@supabase/supabase-js";
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'
import { SideMenu } from '../components';
const supabase = createClient(
  "https://otdflaxttswulcejfjkl.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im90ZGZsYXh0dHN3dWxjZWpmamtsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njk1MTc5MjksImV4cCI6MTk4NTA5MzkyOX0._ONM3lwM8gkMrTer2Y0fC0Opj-k0cakEB1Dm0BO6om0"
);




const Achievements = () => {
  
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  // Login and User Data
  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then((value) => {
        if (value.data.user) {
          setUser(value.data.user);
          axios.get(`https://api.repticjs.com/user/${value.data.user.user_metadata.sub}`).then((data) => setUserData(data.data))
          
        }
      });
    }

    getUserData();
  }, []);



  return (
    <>
    <Box>
        <SideMenu />
    </Box>
    <Box>
        
    </Box>
    </>
  );
}

export default Achievements