import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import React from 'react';

import { HomePage, Deck, Decks, UserDecks, Dashboard, Achievements } from './pages'
import { SideMenu } from './components'

const App = () => (
    <BrowserRouter>
    
    <Box sx={{ }}>
        <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/deck/:id" exact element={<Deck />} />
            <Route path="/decks" exact element={<Decks />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/dashboard/achievements" exact element={<Achievements />} />

        </Routes>
    </Box>

    </BrowserRouter>
)

export default App