import React, { useEffect, useState } from 'react'
import Sidebar from 'react-sidebar';
import axios from 'axios';
import { Box, Typography, Button } from '@mui/material'
import { Link, useNavigate } from "react-router-dom";

import { SideBarData } from '../utils/SideBar/SideBarData' 
import '../utils/SideBar/SideBar.css'

import LoginIcon from '@mui/icons-material/Login';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai" 
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import repticJS from '../utils/image/repticJS.png';


// supabase
import { createClient } from '@supabase/supabase-js'
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'
const supabase = createClient(
  "https://otdflaxttswulcejfjkl.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im90ZGZsYXh0dHN3dWxjZWpmamtsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njk1MTc5MjksImV4cCI6MTk4NTA5MzkyOX0._ONM3lwM8gkMrTer2Y0fC0Opj-k0cakEB1Dm0BO6om0"
);




const SideMenu = () => {
  
  const [sidebar, setSidebar] = useState(false);
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();


  // Sidebar

  const showSideBar = () => setSidebar(!sidebar)

  // Login and User Data
  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then((value) => {
        if (value.data.user) {
          setUser(value.data.user);
          axios.get(`https://api.repticjs.com/user/${value.data.user.user_metadata.sub}`).then((data) => setUserData(data.data))
          
        }
      });
    }

    getUserData();
  }, []);

  async function signInWithDiscord() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'discord'
    })

  }

  return (
    <>
      <div className="navbar">
        <Link to='#' className="menu-bars">
          <FaBars onClick={showSideBar} className="icons" />
        </Link>
        <Box sx={{ paddingLeft: '45vw', backgroundColor: '#1e2124' }}>
          <center style={{ backgroundColor: '#1e2124' }}>
            <Link to="/"><img src="https://api.repticjs.com/RLCARDS/Logo.png" style={{ width: '55px', backgroundColor: '#1e2124' }} /></Link>
          </center>
        </Box>
        <Box sx={{ paddingLeft: { sm: '18vw', md: '35vw'}, backgroundColor: '#1e2124' }}>
          <Typography variant="h5" sx={{ backgroundColor: '#1e2124', color: '#fff' }}>
          {userData.coins ? (
            <Box>
            🪙{userData.coins}
            </Box>
          ): (
            <Box>
              
            </Box>
          )}
          </Typography>
        </Box>
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <Link to="#" className='menu-bars'>
              <AiOutlineClose onClick={showSideBar} className="icons" />
            </Link>
          </li>
          {SideBarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
          <Box sx={{ paddingTop: { sm: '0', md: '45vh' }, backgroundColor: '#1e2124'}}>
            <center style={{ backgroundColor: '#1e2124' }}>
              <img src={repticJS} style={{ width: '200px', backgroundColor: '#1e2124' }} />
            </center>
          </Box>
        </ul>
      </nav>
    </>
  );
}

export default SideMenu