import React from 'react'
import { SideMenu } from '../components'
import {
    Button,
    Typography,
    Box,
    Card,
    CardContent,
    CardMedia,
    Stack,
    TextField
} from "@mui/material";

import { DecksData } from '../utils/Decks/DecksData' 

import '../utils/Decks/Decks.css'

const Decks = () => {
  return (
    <>
      <SideMenu /> 
      <Box>
      {DecksData.map((item, index) => {
            return (
              <Box>
                  <CardMedia 
                  image={item.icon}
                  alt={item.title}
                  sx={{
                    width: '200px',
                    height: '200px'
                  }}
                  /> 
              </Box>
            )
          })}      
        </Box>
    </>
  )
}

export default Decks