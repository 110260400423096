import React from 'react'
import {  } from "react-icons/fa"
import { AiFillHome } from "react-icons/ai"
import {  } from "react-icons/io"
import { CgCardSpades } from "react-icons/cg"
import { MdAccountCircle } from "react-icons/md"
import { GiAchievement } from 'react-icons/gi'




export const SideBarData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiFillHome className="icons" />,
        cName: 'nav-text'
    },
    {
        title: 'Account',
        path: '/dashboard',
        icon: <MdAccountCircle className="icons" />,
        cName: 'nav-text'
    },
    {
        title: 'Decks',
        path: '/decks',
        icon: <CgCardSpades className="icons" />,
        cName: 'nav-text'
    },
    {
        title: 'Achievements',
        path: '/dashboard/achievements',
        icon: <GiAchievement className="icons" />,
        cName: 'nav-text'
    }
]