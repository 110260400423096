import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography, Box, CardMedia } from '@mui/material'
import { SideMenu } from '../components'
import MenuIcon from '@mui/icons-material/Menu';


import Graphic1 from '../utils/image/Cards.png'


import { createClient } from '@supabase/supabase-js'
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'

const supabase = createClient(
  "https://otdflaxttswulcejfjkl.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im90ZGZsYXh0dHN3dWxjZWpmamtsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njk1MTc5MjksImV4cCI6MTk4NTA5MzkyOX0._ONM3lwM8gkMrTer2Y0fC0Opj-k0cakEB1Dm0BO6om0"
);



const HomePage = () => {

  const navigate = useNavigate();
  

  async function signInWithDiscord() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'discord'
    })

  }

  return (
    <div>
          <Box>
            <SideMenu /> 
          </Box>
          <Box sx={{ borderBottom: '1px solid #36393e'}}>
            <CardMedia 
              image={Graphic1}
                  alt='Graphic #1'
                  sx={{
                    width: { sm: '0', md: '99vw' },
                    height: { sm: '0', md: '500px' },
                  }}
            />
          </Box>
    </div>
  )
}

export default HomePage

{/* <Button onClick={() => signInWithDiscord()} /> */}