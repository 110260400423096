import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, Box, Stack } from '@mui/material';


const Decks = () => {

    const { id } = useParams();
    const [data, setData] = useState([])

    useEffect(() => {

      axios.get(`https://api.repticjs.com/api/${id}`)
      .then((data) => setData(data.data));
      
    }, [id])


    return (
      <div>

      <Box sx={{ paddingTop: '25px' }}>
        <center><img src={`https://api.repticjs.com/RLCARDS/${id}.png`} style={{ width: '250px'}}/></center>
      </Box>

    
    <Box sx={{ paddingTop: '25px' }}>
    <Stack direction={'row'} flexWrap='wrap' justifyContent="start" gap={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       {data.map((item, idx) => (

        <Box>
          <img src={item.url} style={{ width: '400px' }}/>
        </Box>
  
       ))}
    </Stack>
    </Box>
    
      </div>
  
    )
}

export default Decks