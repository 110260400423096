import React from 'react'
const BGCS = 'https://api.repticjs.com/RLCARDS/packs/BGCS.png'
const Silver = 'https://api.repticjs.com/RLCARDS/packs/Silver.png'
const Gold = 'https://api.repticjs.com/RLCARDS/packs/Gold.png'




export const DecksData = [
    {
        title: 'Silver',
        path: '/deck/Silver',
        icon: Silver
    },
    {
        title: 'Silver',
        path: '/deck/Gold',
        icon: Gold
    },
    {
        title: 'BGCS',
        path: '/deck/BGCS',
        icon: BGCS
    },
]