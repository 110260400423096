import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  TextField
} from "@mui/material";
import axios from "axios";

import { createClient } from "@supabase/supabase-js";
import { Auth, ThemeSupa } from "@supabase/auth-ui-react";
import { SideMenu } from '../components'


const supabase = createClient(
  "https://otdflaxttswulcejfjkl.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im90ZGZsYXh0dHN3dWxjZWpmamtsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njk1MTc5MjksImV4cCI6MTk4NTA5MzkyOX0._ONM3lwM8gkMrTer2Y0fC0Opj-k0cakEB1Dm0BO6om0"
);

const Dashboard = () => {
  const [user, setUser] = useState({});
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  async function signInWithDiscord() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'discord'
    })

  }

  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then((value) => {
        if (value.data.user) {
          setUser(value.data.user);
        } else {
          signInWithDiscord()
        }

          axios
          .get(`https://api.repticjs.com/user/deck/${value.data.user.identities[0].id}`)
          .then(async(d) => {
            const t = await d.data.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
            
            console.log(t);
            setCards(t);
          });
      });
    }

    getUserData();
  }, []);

  return (
    <div>
    <SideMenu />
      {user.user_metadata ? (
        <center>
          <Box sx={{ alignItems: 'center' }}>
            <TextField id="filled-basic" 
            label="Search" 
            variant="filled"
            inputProps={{ style: { color: "#fff" } }}
            sx={{ 
              width: '86%',
              color: '#fff',
              fontColor: '#fff',
              borderBottom: '3px solid #36393e'
            }}
            onChange={(d) => setSearch(d.target.value.toLowerCase())}
            />
          </Box> 
          <Box sx={{ paddingTop: "50px", backgroundColor: "#23272a" }}></Box>
          <Box>
            <Stack
              direction={"row"}
              flexWrap="wrap"
              justifyContent="start"
              gap={10}
              backgroundColor="#23272a"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cards.filter(card => card.name.toLowerCase().includes(search)).map((item, idx) => (
                <Box key={idx}>
                  <Card
                    sx={{
                      width: "350px",
                      borderRadius: "0px",
                      height: "400px",
                    }}
                  >
                    <CardMedia
                      image={item.url}
                      alt={item.name}
                      sx={{ width: "100%", height: 350, backgroundColor: '#36393e' }}
                    />
                    <CardContent
                      sx={{ backgroundColor: "#23272a", height: "106px", border: '1px solid #fff' }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        color="#fff"
                      >
                        {item.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Stack>
          </Box>
        </center>
      ) : (
        <h1>Loading ...</h1>
      )}
    </div>
  );
};

export default Dashboard;
